$(document).ready(function() {

  var listfilters = document.getElementById('listFilters');

  if (listfilters) {
    $(window).on('scroll',function () {
      if(listfilters.offsetTop - window.scrollY < 0){
        document.getElementById('listFilters').className = "listFilters listFiltersTOP"
      }else{
        document.getElementById('listFilters').className = "listFilters"
      }
    })
  }


  var seoEditBtn = document.querySelector('.seo #editBtn');
  var seoPreviewBtn = document.querySelector('.seo #previewBtn');
  var seoSaveBtn = document.querySelector('.seo #saveBtn');
  var seoEditDiv = document.querySelector('.seo #editdiv');

  if (seoEditBtn) {
    seoEditBtn.addEventListener('click' , function () {
      seoEditDiv.classList.remove('hidden');
      tinymce.init({
        selector:'#seo_txt',
        plugins: [
          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars code fullscreen',
          'insertdatetime media nonbreaking save table contextmenu directionality',
          'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
        ],
        toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
        toolbar2: 'print preview media | forecolor backcolor emoticons | codesample',
        image_advtab: true,
        theme: 'modern',
      });
    });
  }

  if (seoPreviewBtn)
    seoPreviewBtn.addEventListener('click', function () {
      var txt = tinymce.get('seo_txt').getContent();
      document.querySelector('.seo #txt').innerHTML = txt;
    });

  if (seoSaveBtn)
    seoSaveBtn.addEventListener('click', function () {
      var txt = tinymce.get('seo_txt').getContent();
      var cat = document.querySelector('.seo [name=key]').value;

      $.ajax({
        url: "/ajaxs",
        method: "POST",
        data: {
          mode: 'seo',
          txt: txt,
          key: cat
        }
      }).done(function( data ) {
        if (data.result == 'true') {
          document.querySelector('.seo #seo_txt').innerHTML = txt;
          seoEditDiv.classList.add('hidden');
        }
      })
    });

  $('.clever-link').click(function(){window.open($(this).data('link'), "_self");return false;});


  $(document).on('change', 'select[data-change-title]', function() {$(this).siblings( "span" ).html( $(this).find('option:selected').text()+'&nbsp;' );});

  $(document).on('keydown', 'input[data-only-digits]', function(e){-1!==$.inArray(e.keyCode,[46,8,9,27,13,110,190])||/65|67|86|88/.test(e.keyCode)&&(!0===e.ctrlKey||!0===e.metaKey)||35<=e.keyCode&&40>=e.keyCode||(e.shiftKey||48>e.keyCode||57<e.keyCode)&&(96>e.keyCode||105<e.keyCode)&&e.preventDefault()});

  if (window.location.href.indexOf('results')!=-1) {
    var offsettop = document.getElementById('listFilters').offsetTop;
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= offsettop) {
        $('#listFilters').addClass("listFiltersTOP");
      } else if ($("#listFilters").hasClass( "listFiltersTOP" )) {
        $('#listFilters').removeClass("listFiltersTOP");
        $(window).scrollTop(scroll-10);
      }
    });
  }
  $(document).ready(function() {
    $('[data-pn]').on('click', function (e) {
      $('[data-pn]').removeClass('current')
      $(this).addClass('current');
      if ($(this).data('pn') == 1) {
        $('img.btnSlider').removeClass('naemi').addClass('prodazhbi');
      } else {
        $('img.btnSlider').removeClass('prodazhbi').addClass('naemi');
      }
      $('input[name=pn]').val($(this).data('pn'));
      $('form[name=search]').attr('method', 'post').submit();
    });

    $('.clever-link, [data-link]').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      var target = e.target.getAttribute('target');
      if (!target) {
        target = "_self";
      }
      window.open($(this).data('link'), target);
      return false;
    });
  });


});

function getResp(url, callback, body)
{
  var requester = null;
  if (window.XMLHttpRequest)
    {
      requester = new XMLHttpRequest();
    }
  else
    if (window.ActiveXObject)
      {
        var version = 6;
	while (version >= 3 && !requester)
          {
            try       { requester = new ActiveXObject('Msxml2.XMLHTTP.' + i + '.0'); }
            catch (e) { --version;                                                   }
          }
        if (!requester)
          {
            try       { requester = new ActiveXObject('Microsoft.XMLHTTP');          }
            catch (e) {                                                              }
          }
      }
  if (requester)
    {
      requester.onreadystatechange = function() { if (requester.readyState === 4 && requester.status === 200 && callback) { callback(requester.responseText); } };
      //requester.open('GET', url, true);
      //requester.send(null);
      requester.open(body ? 'POST' : 'GET', url, true);
      if (body)
        {
          requester.setRequestHeader("Content-type", "application/x-www-form-urlencoded;charset=UTF-8");
          // requester.setRequestHeader("Content-length", body.length);
        }
      requester.send(body ? body : null);
    }
  else
    {
      xml = document.createElement('xml');
      if (xml && typeof(xml.addBehavior) !== 'undefined')
        {
          xml.addBehavior('#default#download');
          xml.startDownload(url, callback);
        }
    }
}

function hideCookies() {
  $('#cookies').animate({
  height: 0,
  lineHeight: 0
  }, 500, function() {
    // document.getElementById("cookies").style.display = 'none';
    $('#cookies').slideUp();
    savecookie("cookie_check","1","30");
  });
}

function getcookieval(c_name)
{
  //check for existing cookie
  var i,x,y,ARRcookies=document.cookie.split(";");
  for (i=0;i<ARRcookies.length;i++)
    {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
      x=x.replace(/^\s+|\s+$/g,"");
      if (x==c_name)
        {
          return unescape(y);
        }
    }
  return "";
}

function savecookie(name,value,days,session)
{
  var exdays=100;
  if(days)
    exdays=days;

  var exdate=new Date();
  exdate.setDate(exdate.getDate() + exdays);

  var domain = location.hostname.slice(location.hostname.lastIndexOf('.'));

  var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString()+"; path=/; domain=.imoti" + domain);
  if (session){
    c_value=escape(value)+("; path=/; domain=.imoti" + domain);
  }

  document.cookie=name + "=" + c_value;
}

var timeSubMenu;
function openSubMenu(mode){
  if ( document.getElementById('subMenu').style.display!='block'){
    if ( document.getElementById('myMenu')==null || document.getElementById('myMenu')!=null & document.getElementById('myMenu').offsetTop<=0 ){
      document.getElementById('subMenu').style.display = 'block';
      clearTimeout(timeSubMenu);
      if( mode!="inMyPages"){
        document.getElementById("menuMy").className = "selected";
      }
    }
  } else {
    closeSubMenu(mode);
  }
}
function closeSubMenu(mode){
  timeSubMenu = setTimeout(function() {timeSubMenuFnc(mode)}, 0 /*250*/);
}

function timeSubMenuFnc(mode){
  document.getElementById('subMenu').style.display = 'none';
  if( mode!="inMyPages"){
    document.getElementById('menuMy').className = '';
  }
}

// Хоризонтален слайдер с navigation
function SetSelectedNavigation(sliderName,positionLeft,visElements,elWidth) {

  var widthRow = visElements*elWidth;
  if ((positionLeft%widthRow)==0){
    var selectedNav=Math.abs(positionLeft/widthRow);

    var links=document.querySelectorAll("#"+sliderName+"_navigation a");

    for (var i = 0; i < links.length; i++) {
      if (i==selectedNav) { links[i].className = "current";}
      else                { links[i].className = "";       }
    }
  }
}

function MoveSliderLeft(sliderName, positionLeft){

  var slider = document.getElementById(sliderName);
  slider.style.transform = 'translateX('+positionLeft+'px)';
  slider.style.webkitTransform = 'translateX('+positionLeft+'px)';
  slider.style.msTransform = 'translateX('+positionLeft+'px)';
}

function SlideLeft (sliderName){

  var slider = document.getElementById(sliderName);
  var positionLeft = parseInt(slider.getAttribute("data-position-left"));
  var visElements = parseInt(slider.getAttribute("data-visible-elements"));
  var elWidth = parseInt(slider.getAttribute("data-element-width"));
  var rows = parseInt(slider.getAttribute("data-rows"));

  if (positionLeft == 0)
    positionLeft=-1*visElements*elWidth*(rows-1);
  else
    positionLeft = positionLeft+elWidth;

  slider.setAttribute("data-position-left", positionLeft);

  MoveSliderLeft(sliderName, positionLeft);
  SetSelectedNavigation(sliderName,positionLeft,visElements,elWidth);
}

function SlideRight (sliderName){

  var slider = document.getElementById(sliderName);
  var positionLeft=parseInt(slider.getAttribute("data-position-left"));
  var visElements = parseInt(slider.getAttribute("data-visible-elements"));
  var elWidth = parseInt(slider.getAttribute("data-element-width"));
  var rows = parseInt(slider.getAttribute("data-rows"));

  if (positionLeft == (-1*visElements*elWidth*(rows-1)))
    positionLeft = 0;
  else
    positionLeft = positionLeft-elWidth;

  slider.setAttribute("data-position-left", positionLeft);

  MoveSliderLeft(sliderName, positionLeft);
  SetSelectedNavigation(sliderName,positionLeft,visElements,elWidth);
}

function SlideTo(sliderName,index) {
  var slider = document.getElementById(sliderName);
  var visElements = parseInt(slider.getAttribute("data-visible-elements"));
  var elWidth = parseInt(slider.getAttribute("data-element-width"));

  var positionLeft = index*(-1*elWidth);
  slider.setAttribute("data-position-left", positionLeft);

  MoveSliderLeft(sliderName, positionLeft);
  SetSelectedNavigation(sliderName,positionLeft,visElements,elWidth);
}

//Вертикален слайдер без navigation
function MoveSliderTop(sliderName, positionTop){

  var slider = document.getElementById(sliderName);
  slider.style.transform = 'translateY('+positionTop+'px)';
  slider.style.webkitTransform = 'translateY('+positionTop+'px)';
  slider.style.msTransform = 'translateY('+positionTop+'px)';
}

function SlideTop(sliderName){

  var slider = document.getElementById(sliderName);
  var positionTop = parseInt(slider.getAttribute("data-position-top"));
  var visElements = parseInt(slider.getAttribute("data-visible-elements"));
  var elHeight = parseInt(slider.getAttribute("data-element-height"));
  var rows = parseInt(slider.getAttribute("data-rows"));

  if (positionTop == 0)
    positionTop=-1*visElements*elHeight*(rows-1);
  else
    positionTop = positionTop+(elHeight*visElements);

  slider.setAttribute("data-position-top", positionTop);

  MoveSliderTop(sliderName, positionTop);
}

function SlideBottom(sliderName){

  var slider = document.getElementById(sliderName);
  var positionTop=parseInt(slider.getAttribute("data-position-top"));
  var visElements = parseInt(slider.getAttribute("data-visible-elements"));
  var elHeight = parseInt(slider.getAttribute("data-element-height"));
  var rows = parseInt(slider.getAttribute("data-rows"));

  if (positionTop == (-1*visElements*elHeight*(rows-1)))
    positionTop = 0;
  else
    positionTop = positionTop-(elHeight*visElements);

  slider.setAttribute("data-position-top", positionTop);

  MoveSliderTop(sliderName, positionTop);
}

function showAgHint(agencyElem, agencyLogo, agencyName, agencyTown, agencyPhone) {
  var hint=document.getElementById('agencyHint');
  if (agencyElem) {

    var agenciesWidget=document.getElementById('agenciesWidget');
    var rectWidget = agenciesWidget.getBoundingClientRect();
    var rectElem = agencyElem.getBoundingClientRect();

    var elemLeft = rectElem.left - rectWidget.left + 10;
    var elemTop = rectElem.top - rectWidget.top - 184;

    hint.style.left=elemLeft+"px";
    hint.style.top=elemTop+"px";

    document.getElementById('ag_hint_logo').src = "https://www.imot.bg/images/logos/small/"+agencyLogo+".pic";
    document.getElementById('ag_hint_name').innerHTML = agencyName;
    document.getElementById('ag_hint_location').innerHTML = agencyTown;
    document.getElementById('ag_hint_phone').innerHTML = agencyPhone;

  }

  hint.style.display = 'block';
}
function hideAgHint() {
  document.getElementById('agencyHint').style.display = 'none'
}

function supportsSVG() {
  return !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', "svg").createSVGRect;
}

function fmapset(src) {
  if ( supportsSVG() ) {
    if ( (src=='index') && craionsToId && document.search && document.search.sraion ) {
      $('#rs').empty();
      $("#spisakRaioni").empty();
      var sr=document.search.sraion.value.split('~');
      if  ( sr && (sr.length>0) ) {
        var id2cr=new Object();
        for (var cr in craionsToId) {
          id2cr[craionsToId[cr].id]=cr;
        }
        sr.forEach(function(item){
          if ( item && id2cr[item] ) {
            $('#rs').append('<option value="'+item+'">'+id2cr[item]+'</option>');
            $("#spisakRaioni").append("<div> <span>"+id2cr[item]+"</span> <a title='Изтрий' onclick='javascript:spisakRaioniRemove("+item+");'>x</a> </div>");
          }
        });
      }
    }

    var fmap=$('.citysvg path, .citysvg circle, .regionsvg path, .regionsvg circle');
    if ( fmap ) {
      fmap.removeAttr( "selected" );
      fmap.css({fill: function(){return $(this).attr('fill')}});
    }

    var regionsval_sel=new Array();
    var regionstxt_sel=new Array();
    $('#rs option').each(function(){
      var opt_val=$(this).val();
      var opt_text=$(this).text();

      var re_val = new RegExp("'"+opt_val+"'\\,?");
      regionsval_sel.push(re_val);
      var re_txt = new RegExp("'"+opt_text+"'\\,?");
      regionstxt_sel.push(re_txt);

      var region_sel=$('.citysvg path, .citysvg circle, .regionsvg path, .regionsvg circle').filter(function(){return $(this).find('title').text()===opt_text});
      if ( region_sel ) {
        region_sel.attr( "selected", "selected" );
        region_sel.css({fill: "#22A0DA"});
      }
    });

    var paths_reg=$('.citysvg path').filter(function(){return $(this).find('title').data('raioni')!==undefined });
    paths_reg.each(function(){
      var raioni_text=$(this).find('title').data('raioni');
      regionstxt_sel.forEach(function(item){
        raioni_text=raioni_text.replace(item,"");
      });
      if ( !raioni_text ) {
        $(this).attr('selected','selected');
        $(this).css({fill: "#22A0DA"});
      }
    });

    paths_reg=$('.regionsvg path');
    paths_reg.each(function(){
      var raioni_title=$(this).find('title').text();
      if ( raioni_title && regionIDS[raioni_title] && (regionIDS[raioni_title].length>0) ) {
        var raioni_val="'"+regionIDS[raioni_title].join("','")+"'";
        regionsval_sel.forEach(function(item){
          raioni_val=raioni_val.replace(item,"");
        });
        if ( !raioni_val) {
          $(this).attr('selected','selected');
          $(this).css({fill: "#22A0DA"});
        }
      }
    });
  }
}

function addpathssel(paths_sel,paths_all_class) {
  var selected_area_raioni=paths_sel[0].find('title').data('raioni');
  if ( selected_area_raioni ) {
    var paths_all=$(paths_all_class);
    if ( paths_all ) {
      paths_all.each(function(){
        var path_title=$(this).find('title').text();
        if ( path_title && (selected_area_raioni.search("'"+path_title+"'")>=0) ) {
          paths_sel.push($(this));
        }
      });
    }
  }
}

function toright() {
  var same = 0;
  var target;
  var index=document.search.ri.selectedIndex;

  if (document.search.ri.options[index]){
    var ime=document.search.ri.options[index].text;
    for ( fi=0;fi<document.search.rs.length;fi++ ) {
      var t = document.search.rs.options[fi].text;
      if (t == ime){
        same = 1;
        target=fi;
        document.search.rs.selectedIndex=fi;
      };
    }

    if (same==0) { //добавяне

      var keyRegion = '';
      var setSelFlag = 0;

      if (region_flag==1){ //от регион

        var tmpl_regionIDS = JSON.parse(JSON.stringify(regionIDS));

        //да се намери необходимата община в keyRegion и да се премахне избрания елемент
        var val = document.search.ri.options[index].value;
        floop1:
        for (var key in regionIDS) {
          floop2:
          for (var j=0; j<regionIDS[key].length;j++) {
            if(tmpl_regionIDS[key][j]==val){
              tmpl_regionIDS[key].splice(j, 1);
              keyRegion = key;
              break floop1;
            }
          }
        }

        //да се намери премахнат всички избрани елементи
        loop1:
        for ( var i=0;i<document.search.rs.length;i++ ) {
          var selectedTownVal = document.search.rs.options[i].value;

          loop2:
          for (var key in regionIDS) {
            loop3:
            for (var j=0; j<regionIDS[key].length;j++) {
              if(tmpl_regionIDS[key][j]==selectedTownVal){
                tmpl_regionIDS[key].splice(j, 1);
                break loop2;
              }
            }
          }
        }

        //Ако няма повече елементи трябва да маркираме областта
        if (keyRegion && (tmpl_regionIDS[keyRegion].length == 0))
          setSelFlag = 1;
      }

      if (keyRegion==''){ //от град ако не е намерено в регион
        keyRegion = document.search.ri.options[index].text;
        setSelFlag = 1;
      }

      if ( keyRegion!='' ){
        document.search.rs.options[document.search.rs.length]=new Option (document.search.ri.options[index].text, document.search.ri.options[index].value);
      }

      updr();
    } else {
      delright();
    }

    if ( document.search.rs.length>1 ){
      document.search.rs.options[0]=new Option ('------------------------','');
    } else {
      document.search.rs.options[0]=new Option ('ВСИЧКИ РАЙОНИ','');
    }
  }
}

function delright()
{
  if ( document.search ){

    var index=document.search.rs.selectedIndex;
    var length=document.search.rs.length;
    var keyRegion = '';

    if (document.search.rs.options[index]){

      //приемам, че е регион и търся кое трябва да се размаркира от картата
      if (region_flag) {

        var tmpl_regionIDS = JSON.parse(JSON.stringify(regionIDS));

        var val = document.search.rs.options[index].value;
        loop1:
        for (var key in regionIDS) {
          loop2:
          for (var j=0; j<regionIDS[key].length;j++) {
            if(tmpl_regionIDS[key][j]==val){
              tmpl_regionIDS[key].splice(j, 1);
              keyRegion = key;
              break loop1;
            }
          }
        }
      }
      //приемам, че е град и търся кое трябва да се размаркира от картата
      if ( (keyRegion=='') && document.search.rs.options[document.search.rs.selectedIndex] )
        keyRegion=document.search.rs.options[document.search.rs.selectedIndex].text;

      if ( index>=1 ){
        for ( i=index;i<(length-1);i++ ) {
          document.search.rs.options[i]=new Option (document.search.rs.options[i+1].text,document.search.rs.options[i+1].value);
        }

        document.search.rs.length--;
        if ( document.search.rs.length==1 ){
          document.search.rs.options[0]=new Option ('ВСИЧКИ РАЙОНИ','');
        }
      }

      if ( document.search.rs.length>1 ){
        document.search.rs.options[0]=new Option ('------------------------','');
      } else {
        document.search.rs.options[0]=new Option ('ВСИЧКИ РАЙОНИ','');
      }
      updr();
    }
  }
}

function delrightall()
{
  if ( document.search && document.search.rs &&  document.search.sraion)
    {
      $("#rs").empty();
      document.search.rs.length=0;
      document.search.rs.options[0]=new Option ('ВСИЧКИ РАЙОНИ','');
      updr();
    }
}

function updr()
{
  if ( document.search )
    {
      if ( document.search.rs )
        {
          document.search.sraion.value='';
          var length=document.search.rs.length;
          for ( i=1;i<length;i++ ) {document.search.sraion.value=document.search.sraion.value+document.search.rs.options[i].value+'~';}
          fmapset();
        }
    }
}

function spisakRaioniRemove(id) {
  if ( document.search && document.search.sraion ) {
    document.search.sraion.value=document.search.sraion.value.replace(id+'~','');
    fmapset('index');
  }
}

function srcvichange(vigroup,vinom,callflag)
{
  var srcform;
  if ( document.search ) srcform=document.search
  else if ( document.drawSearchForm ) srcform=document.drawSearchForm;

  if ( (vigroup>0) && srcform && srcform.pubtype )
    {
      var vigr_obj=document.getElementById('vigroupsjs');
      var vigrcol_obj=document.getElementById('vigroupscol');
      if ( vigr_obj && vigrcol_obj )
        {
          var vigr_array = eval('new Object('+vigr_obj.value+')');
          var vigrcol_array = eval('new Object('+vigrcol_obj.value+')');
          if ( vigr_array && vigrcol_array )
            {
              srcform.pubtype.value='';
              var vicheck=0;
              var vi_array={};
              var gr_array={};
              for ( var i in vigr_array )
                {
                  var vi_obj=document.getElementById('vi'+i);
                  if ( vi_obj )
                    {
                      if ( vigr_array[i]==vigroup )
                        {
                          if ( vi_obj.checked )
                            {
                              vicheck+=1;
                              srcform.pubtype.value+=i+'~';
                            }
                        }
                      else
                        {
                          vi_obj.checked=false;
                          vi_obj.disabled=true;
                        }
                      vi_array[i]=vi_obj;
                      gr_array[vigr_array[i]]=1;
                    }
                }
              if ( vicheck==0 )
                {
                  vigrcol_array[0]=vigrcol_array[1];
                  for ( var i in vigr_array ) vi_array[i].disabled=false;
                }
              for ( var i in gr_array )
                {
                  var gr_obj=document.getElementById('gr'+i);
                  if ( gr_obj )
                    {
                      if ( i==vigroup ) gr_obj.style.backgroundColor=vigrcol_array[1];
                      else              gr_obj.style.backgroundColor=vigrcol_array[0];
                    }
                }

              if ( (vicheck==0) || ((vicheck==1) && vi_array[vinom] && vi_array[vinom].checked) )
                {
                  if ((srcform.curr) && (vigroup==6))
                    srcform.curr.value='лв.';

                  if (callflag==2)
                    {
                      srcform.page.value=1;
                      srcform.searchres.value='';
                    }
                  else
                    {
                      srcform.act.value=2;
                    }

                  updr();

                  removeEmpty();
                  srcform.submit();
                }
            }
        }
    }
}

function pricenegcheck(priceneg,price)
{
  if ( priceneg && price )
    {
      if ( priceneg.checked )
        {
          if ( confirm ( 'ВНИМАНИЕ!!!\nВ случай, че изберете цена при запитване,\nВашата обява ще бъде класирана след всички обяви с обявени цени!' ) )
            {
              price.value='';
            }
          else
            {
              priceneg.checked=false;
            }
        }
    }
}

function pricenegtype()
{
  if ( document.pub && document.pub.priceneg )
    document.pub.priceneg.checked=false;
}

//Phone change
function ShowPhonePopup(msg)
{
  var messObj=document.getElementById("phone_txt");
  var phonePopupObj=document.getElementById("phonePopup");
  if ( messObj && phonePopupObj )
    {
      messObj.innerHTML=msg;
      phonePopupObj.style.display = 'block';
    }
}
function HidePhonePopup()
{
  var phonePopupObj=document.getElementById("phonePopup");
  var messObj=document.getElementById("phone_txt");
  if ( messObj && phonePopupObj )
    {
      messObj.innerHTML="";
      phonePopupObj.style.display = 'none';
    }
}
function CheckPhone(phone)
{
  var patterns = /[^0-9]/g;
  var caretPos = phone.selectionStart;

  var replaced = phone.value.search(patterns) >= 0;
  if(replaced)
    {
      phone.value = phone.value.replace(patterns, '');
      phone.setSelectionRange(caretPos-1, caretPos-1);
    }

  if( phone.value.length>=10 )
    {
      if ( phone.value.match(/^(?:(?:(?:00)?359)|0)(?:87|88|89|98|99)\d{7}$/) )
        {
          ShowPhonePopup('<b>ВАЖНО</b>: С цел подсигуряване надеждността на публикуваните в сайта обяви, на посоченият от Вас телефон ще получите ключ, който трябва да въвдете на следващата стъпка.<br/>Получаването на ключа и публикуването на нормална обява е НАПЪЛНО БЕЗПЛАТНО!');
        }
      else
        {
          ShowPhonePopup('Моля, въведете <b>български мобилeн телефон</b> (GSM) за връзка с Вас.');
        }
    }
  else if (phone.value.length>1)
    {
      if ((phone.value.substring(0,2) == '08' || phone.value.substring(0,2) == '09' || phone.value.substring(0,4) == '3598' || phone.value.substring(0,4) == '3599' || phone.value.substring(0,6) == '003598' || phone.value.substring(0,6) == '003599'))
        HidePhonePopup();
      else
        ShowPhonePopup('Моля, въведете <b>мобилeн телефон</b> (GSM) за връзка с Вас.');
    }
  else
    {
      HidePhonePopup();
    }
}

function validateEmail(email) {
    var re = /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
    return re.test(email);
}
function CheckEmail(email)
{
  var namesObj=document.getElementById('names');
  var passwcObj=document.getElementById('passwc');
  if ( namesObj && passwcObj )
    {
      var crttable=function(res)
      {
        var result = eval('(' + res + ')');
        if ( result )
          {
            if ( result.names )
              {
                namesObj.value=result.names;
                namesObj.disabled=true;
              }
            else
              {
                passwcObj.style.display='table-row';
              }
          }
      }

      namesObj.disabled=false;
      passwcObj.style.display='none';
      if ( validateEmail(email.value) )
        getResp( '/checkemail', crttable, ('email='+email.value+'&r='+Math.random()) );
    }
}

function LoadPicts(picts)
{
  var filelistObj=document.getElementById('filelist');
  if ( filelistObj && picts )
    {
      var pictshtml='';
      var i=0;
      while ( i<picts.length )
        {
          if ( picts[i] )
            {
              pictshtml+='<div class="preview">\n';
              pictshtml+='<a class="delete-pic" alt="Изтриване на снимката" title="Изтриване на снимката" href="#">Delete</a>\n';
              pictshtml+='<div class="img" style="background-image: url('+picts[i]+');"></div>\n';
              pictshtml+='</div>\n';
            }
          i++;
        }
      filelistObj.innerHTML=pictshtml;
    }
}

function SavePicts()
{
  if ( document.pub.picts )
    {
      var pictslist='';
      var imgs=document.getElementsByClassName("img");
      if ( imgs )
        {
          var i=0;
          while ( (i<imgs.length) && (i<17) )
            {
              var imgbgr=imgs[i].style.backgroundImage;
              var imgname=imgbgr.match(/1[a-s]\d{15}_[\da-zA-Z]{1,2}\.(pic|jpg|png|gif)/);
              if ( !imgname ) imgname=imgbgr.match(/2[a-t]\d{15}_[\da-zA-Z]{1,2}\.(pic|jpg|png|gif)/);
              if ( !imgname ) imgname=imgbgr.match(/[a-z\d]{32}_\d{1,3}\.(pic|jpg|png|gif)/)
              if ( imgname && (imgname.length==2) )
                pictslist+=imgname[0];
              i++;
              if ( i<imgs.length ) pictslist+='/';
            }
          document.pub.picts.value=pictslist;
        }
    }
}

function pubadv()
{
  if ( document.pub && document.pub.subm )
    {
      SavePicts();
      document.pub.subm.innerHTML='ПУБЛИКУВАНЕ ...';
      document.pub.act.value='2';
      document.pub.submit();
    }
}

function removeDisabled()
{
  var i=0;
  while ( i<document.search.length )
    {

      if ( document.search[i].id.indexOf("vi")==-1 )
        document.search[i].disabled=false;
      i++;
    }
}

function removeEmpty()
{
  searchFormSubmit();

  if (document.search.ri)
    document.search.ri.disabled=true;

  var i=0;
  while ( i<document.search.length )
    {
      if (document.search[i].value == "")
        document.search[i].disabled=true;
      i++;
    }
}

function openFiltermenu(i) {
  if( document.getElementById("filterMenu"+i).style.display != "block" ) {
    savecookie("imotResOpenMenu",i,1,1);
    document.getElementById("filterMenu"+i).style.display = "block";
    document.getElementById('menuItem'+i).style.backgroundColor="#FFFFFF";
    document.getElementById('filterLink'+i).style.backgroundPosition="right -144px";
    for(var n=1; n<=6; n++){
      if ((n!=i) && document.getElementById("filterMenu"+n)) {
        document.getElementById("filterMenu"+n).style.display = "none";
        document.getElementById('menuItem'+n).style.removeProperty("background-color");
        document.getElementById('filterLink'+n).style.backgroundPosition="right 56px";
      }
    }
  }else{
    savecookie("imotResOpenMenu",0,1,1);
    document.getElementById("filterMenu"+i).style.display = "none";
    document.getElementById('menuItem'+i).style.removeProperty("background-color");
    document.getElementById('filterLink'+i).style.backgroundPosition="right 56px";
  }
}

function openMenuOnReload() {
  var i = getcookieval('imotResOpenMenu');
  if (i>0)
    openFiltermenu(i);
}

var visible = false;
function snapFiltersOnTop() {
  if(document.body.scrollTop >= 190){
    document.getElementById('listFilters').className = "listFilters listFiltersTOP"
  }else{
    document.getElementById('listFilters').className = "listFilters"
  }
}

function notepad_act (adv,refresh,callflag){

  var request = $.ajax({
    url: "/ajaxs?mode=notepad&adv="+adv,
    method: "GET",
    dataType: 'html'
  });

  request.done(function( data ) {
    var json = jQuery.parseJSON( data );

    if (json.result)
      {
        var selector="#notepad_"+adv;
        var classAdd = json.classAdd;
        var classRem = json.classRem;

        if (callflag=="1")
          {
            var selector2="#notepad1_"+adv;
            if (classAdd){
              classAdd=classAdd.replace(/ToBel/g,"");
              $(selector).html('Изтрий от бележника');
              $(selector2).html('Изтрий от бележника');
            }
            if (classRem){
              classRem=classRem.replace(/ToBel/g,"");
              $(selector).html('Добави в бележника');
              $(selector2).html('Добави в бележника');
            }

            $(selector).addClass(classAdd);
            $(selector).removeClass(classRem);

            $(selector2).addClass(classAdd);
            $(selector2).removeClass(classRem);
          }
        else
          {
            $(selector).html(json.textin);
            $(selector).addClass(classAdd);
            $(selector).removeClass(classRem);
          }

        if (refresh==1)
          window.location.reload();
      }
    else
      {
        alert(json.message);
      }
  });
}

function openZapitvane(){
var zapitvaneObj=document.getElementById('zapitvane');
var contact_formObj=document.getElementById('contact-form');
var send_btnObj=document.getElementById('send_btn');
if ( zapitvaneObj && contact_formObj && send_btnObj )
  {
    zapitvaneObj.style.display = 'block';
    contact_formObj.style.display = 'block';
    send_btnObj.style.display = 'block';
  }
}
function DirectcloseZapitvane(){
var zapitvaneObj=document.getElementById('zapitvane');
if ( zapitvaneObj )
  zapitvaneObj.style.display = 'none';
}

function closeSendMessage(){
  document.getElementById('contact-form').style.display = 'none';
  document.getElementById('send_btn').style.display = 'none';
  $('#messageStatus').show();
  $('#messageStatus').animate({ opacity: 1,}, 500,
  function() {
    timeVar = setTimeout(function(){
      $('#messageStatus').animate({
        height: 0,
        marginTop:0,
        borderWidth:0,
        }, 400, function() {
          document.getElementById('zapitvane').style.display = 'none';
      });
    }, 3000);
  });
}

function sendToFriend(){
  var sendToFriendObj=document.getElementById('sendToFriend');
  var share_advertObj=document.getElementById('share_advert');
  var share_btnObj=document.getElementById('share_btn');
  if ( sendToFriendObj && share_advertObj && share_btnObj )
    {
      sendToFriendObj.style.display = 'block';
      share_advertObj.style.display = 'block';
      share_btnObj.style.display = 'block';
    }
}
function DirectcloseSendToFriend(){
  var sendToFriendObj=document.getElementById('sendToFriend');
  if ( sendToFriendObj )
    sendToFriendObj.style.display = 'none';
}

function closeSendToFriend(){
  document.getElementById('share_advert').style.display = 'none';
  document.getElementById('share_btn').style.display = 'none';
  $('#messageStatusShare').show();
  $('#messageStatusShare').animate({ opacity: 1,}, 500,
  function() {
    timeVar = setTimeout(function(){
      $('#messageStatusShare').animate({
        height: 0,
        marginTop:0,
        borderWidth:0,
        }, 400, function() {
          document.getElementById('sendToFriend').style.display = 'none';
      });
    }, 3000);
  });
}

function ShareAdvert(){

  $("#share_advert").each(function(){
    $(this).find('.changecolor').each(function(){
      $(this).parent().removeClass("formError" );
    });
  });

  var request = $.ajax({
    url: "/ajaxs?"+$(document.share).serialize(),
    method: "GET",
    dataType: 'html'
  });

  request.done(function( data ) {
    var json = jQuery.parseJSON( data );
    if ( json.error == "true")
      {
        $('#messageFailShare').html('Попълнете полетата коректно!');
        if ( json.error_field=="chkcond" ) {
          $('#messageFailShare').html('Трябва да се съгласите с Общите условия<br/>и политиката ни за защита на личните данни!');
        }
        else {
          $("[name='"+json.error_field+"']").parent().addClass( "formError" );
        }
        $('#messageFailShare').show();
      }
    else
      {
        $('#messageFailShare').hide();
        closeSendToFriend();

        $("#share_advert").each(function(){
          $(this).find('.changecolor').each(function(){
            $(this).parent().removeClass( "formError" );
            $(this).val("");
          });
        });
        $("#share_advert").find("input[name='chkcond']").attr('checked', false);
      }
  });
}

function MakeContact (flag){
  $("#contact-form").each(function(){
    $(this).find('.changecolor').each(function(){
      $(this).parent().removeClass( "formError" );
    });
  });

  var request = $.ajax({
    url: "/ajaxs?"+$(document.contact).serialize(),
    method: "GET",
    dataType: 'html'
  });

  request.done(function( data ) {
    var json = jQuery.parseJSON( data );

    if ( json.error == "true")
      {
        $('#messageFail').text('Попълнете полетата коректно!');
        if ( json.error_field=="f4" ) {
          $('#messageFail').text('Трябва да се съгласите с Общите условия<br/>и политиката ни за защита на личните данни!');
        }
        else {
          $("[name='"+json.error_field+"']").parent().addClass( "formError" );
        }
        $('#messageFail').show();
      }
    else
      {
        $('#messageFail').hide();
        closeSendMessage();

        $("#contact-form").each(function(){
          $(this).find('.changecolor').each(function(){
            $(this).parent().removeClass( "formError" );
            $(this).val("");
          });
        });
        $("#contact-form").find("input[name='f4']").attr('checked', false);
      }
  });
}

function popitup(url) {
  var width_tmp=(window.innerWidth/2)-250;
  var height_tmp=(window.innerHeight/2)-200;
  newwindow=window.open(url,'name','width=500,height=400,screenx='+width_tmp+',screeny='+height_tmp);
  if (window.focus) {newwindow.focus()}
  return false;
}

function errorMessage() {
  var errorMessageObj=document.getElementById('errorMessage');
  if ( errorMessageObj )
    {
      errorMessageObj.style.height = '0';
      errorMessageObj.style.paddingTop = '0';
      errorMessageObj.style.paddingBottom = '0';
      errorMessageObj.style.display = 'block';
      $('#errorMessage').animate({
        height: 55,
        paddingTop: 20,
        paddingBottom: 20,
      }, 700, function() {
                    //
            });
    }
}

function getCheckedValue(radioObj)
{
  if (!radioObj) return "";
  var radioLength = radioObj.length;
  if ( radioLength == undefined )
    if ( radioObj.checked )
      return radioObj.value;
    else
      return "";
  for ( var i = 0; i < radioLength; i++ )
    {
      if ( radioObj[i].checked )
        {
          return radioObj[i].value;
        }
    }
  return "";
}

function setpaybutton()
{
  if( document.payform && document.payform.psum )
    {
      var paybuttonObj=document.getElementById('paybutton');
      if ( paybuttonObj )
        {
          if ( document.payform.psum.value!='' ) paybuttonObj.innerHTML=' от '+document.payform.psum.value+' лв.';
          else                                   paybuttonObj.innerHTML='';
        }
    }
}

function payrequest()
{
  if ( document.payform )
    {
      if ( document.payform.chkcond && document.payform.chkcond.checked  )
        {
          var ptypeval=getCheckedValue(document.payform.ptype);
          if ( ptypeval>0 )
            {
              if ( document.payform.psum )
                {
                  var psumval=document.payform.psum.value;
                  var psumreg=/^\d{1,5}$/;
                  if ( psumreg.test(psumval) && (10<=psumval) && (psumval<=10000) )
                    {
                      document.payform.act.value='2';
                      document.payform.submit();
                    }
                  else
                    {
                      alert('Сумата трябва да е цяло число от 10 до 10000 лв.');
                    }
                }
            }
          else
            {
              alert('Моля изберете метод на плащане!');
            }
        }
      else
        {
          alert('Не сте се съгласили с общите условия!');
        }
    }
}

function payinvoice()
{
  if( document.payform && document.payform.invlist && invlistarr )
    {
      var ind=document.payform.invlist.options[document.payform.invlist.selectedIndex].value;
      if ( invlistarr[ind] )
        {
          if ( document.payform.company ) document.payform.company.value=invlistarr[ind][0];
          if ( document.payform.address ) document.payform.address.value=invlistarr[ind][1];
          if ( document.payform.eik     ) document.payform.eik.value=invlistarr[ind][2];
          if ( document.payform.ndds    ) document.payform.ndds.value=invlistarr[ind][3];
          if ( document.payform.mol     ) document.payform.mol.value=invlistarr[ind][4];
        }
    }
}
var lastSearchesTimeout ;
function openLastSearches() {
  document.getElementById('lastSearches').style.display = 'block';
  clearTimeout(lastSearchesTimeout);
}
function closeLastSearches() {
  lastSearchesTimeout = setTimeout("document.getElementById('lastSearches').style.display = 'none'", 750);
}

function loadLastSearches() {
  var lastSearchesObj=document.getElementById("lastSearches");
  if ( (typeof(Storage) !== "undefined") && lastSearchesObj )
    {
      var lastsrcstor=localStorage.getItem('lastsrc');
      if ( lastsrcstor )
        {
          var lastsrcstorarr = eval('new Object('+lastsrcstor+')');
          if ( lastsrcstorarr )
            {
              var i=0;
              while ( (i<3) && (i<lastsrcstorarr.length) )
                {
                  lastSearchesObj.innerHTML+='<a href="'+lastsrcstorarr[i].srcurl+'">'+lastsrcstorarr[i].srctxt.replace('<br>', '')+'</a>';
                  i++;
                }
            }
        }
    }
}

function getCookie(c_name)
{
if (document.cookie.length>0)
  {
  c_start=document.cookie.indexOf(c_name + "=");
  if (c_start!=-1)
    {
    c_start=c_start + c_name.length+1;
    c_end=document.cookie.indexOf(";",c_start);
    if (c_end==-1) c_end=document.cookie.length;
    return unescape(document.cookie.substring(c_start,c_end));
    }
  }
return "";
}

function openLogPopup() {
  var logpopupObj=document.getElementById('logpopup');
  if ( logpopupObj ) {
      logpopupObj.style.opacity = 1;
      logpopupObj.style.display = 'block';

      logpopupObj.innerHTML='<iframe class="logPopupFrame" src="/loginregistrations" name="loginframe"  marginwidth=0 marginheight=0 frameborder=0 scrolling="no"></iframe>';
  }
}

function showDetails(link,url) {
  if ( link ) {
    link.href=url;
  }
}

function searchFormSubmit() {
  var srcform;
  var action = '';

  if ( document.search ) srcform=document.search
  else if ( document.drawSearchForm ) srcform=document.drawSearchForm;
  if (srcform.action == window.location.protocol + '//' + window.location.hostname + '/results') {

    if (srcform.pn.value == 1) {
      action = '/prodazhbi'
    } else {
      action = '/naemi'
    }
    var pubtype = srcform.pubtype.value;
    pubtype = pubtype.replace(/~+$/,'').split('~');
    if (pubtype.length == 1 && typeof pubtype_slugs != 'undefined' && typeof pubtype_slugs[srcform.pn.value][pubtype[0]] != 'undefined') {
      action = action + '/' + pubtype_slugs[srcform.pn.value][pubtype[0]];
      srcform.pubtype.value = '';
    }
    srcform.pn.value = '';
    srcform.action = action;
  }

}
function submitIndex() {
  document.search.action='/results';
  removeEmpty();

  if (document.querySelectorAll('form[name=search] [disabled]').length == document.search.length) {
      window.location = document.search.action;
  } else {
    document.search.submit();
  }
  return false;
}

function showPhone(string, ida) {
  const str = string.replace(/\D/g,"").replace(/^(\d{4})(\d{3})/,"$1 $2 ");

  document.querySelectorAll('.phoneNumber').forEach( function (phone) {
     phone.innerHTML = str;
   });

  // ga('send', {
  //   hitType: 'event',
  //   eventCategory: 'Adverts',
  //   eventAction: 'CallButtonClicked',
  //   eventLabel: 'Desktop'
  // });

  document.getElementById('rtb_details').src = 'https://creativecdn.com/tags?id=pr_mJVnCOPe4XHzhNqpNdTl_orderstatus2_0_' + str + '_' + ida + '&cd=default';
}
